import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, buildImageObj } from "../lib/helpers";
import HeroSection from "../components/hero-section/hero-section";
import BlockContent from "../components/block-content";
import Icon from "../components/icon";
import loadable from "@loadable/component";
import { imageUrlFor } from "../lib/image-url";

const TestiminialPreviewComponent = loadable(() =>
  import("../components/customer-testimonial-preview/customer-testimonial-preview")
);

export const query = graphql`
  query CustomerPageQuery {
    customers: allSanityCustomers {
      nodes {
        title
        customers {
          name
          _rawDescription
          logo {
            alt
            asset {
              _id
            }
          }
        }
      }
    }
    site: sanityCustomersPage {
      subtitle
      title
      seo
      keywords
      _rawDescription
      id
      mainImage {
        asset {
          _id
        }
        alt
      }
    }
  }
`;

const CustomerPage = props => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const customersData = data && data.customers.nodes;
  const customers = customersData[0].customers;

  const site = (data || {}).site;

  const imageUrl = imageUrlFor(buildImageObj(site.mainImage))
    .width(600)
    .width(600)
    .fit("fill")
    .url();

  return (
    <Layout>
      <SEO title="Customers" description={site.seo} image={imageUrl} keywords={site.keywords} />
      {site && site.title && (
        <HeroSection>
          <div>{site._rawDescription && <BlockContent blocks={site._rawDescription || []} />}</div>
          <div
            data-sal="slide-up"
            data-sal-delay="350"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Icon symbol="customersIcon"></Icon>
          </div>
        </HeroSection>
      )}

      <Container role="complementary">
        {customers && (
          <TestiminialPreviewComponent customers={customers}></TestiminialPreviewComponent>
        )}
      </Container>
    </Layout>
  );
};

export default CustomerPage;
